//AbsenceForm
export interface FormValues {
  startDate: Date | null;
  endDate: Date | null;
  reason: AbsenceReason;
  information: string;
  userId?: string;
}

//CalenderView
export type BankHoliday = {
  country: string,
  iso: string,
  year: number,
  date: string,
  day: string,
  name: string,
  type: string,
}

//AbsenceFormPopUp
export interface AbsenceFormProps {
  closePopUp: () => void;
  updatePendingRequests: (newRequest: RequestData) => void;
}

export interface AbsenceFormPopupProps {
  open: boolean;
  closePopUp: () => void;
  updatePendingRequests?: (newRequest: RequestData) => void;
}

//Absence
export const AbsenceReasons = [
  'Sick Leave',
  'Sick Child',
  'Vacation',
  'Unpaid Leave',
  'Day Off',
  'Conference',
  'Work Travel',
  'Moving Day',
  'Parental Leave',
  'Military Training'
] as const;
export type AbsenceStatus = 'Requested' | 'Approved' | 'Declined';
export type AbsenceReason = typeof AbsenceReasons[number];

export interface Absence {
  id: number;
  userId: string;
  delegatedToUserId?: string;
  startDate: string;
  endDate: string;
  reason: AbsenceReason;
  status: AbsenceStatus;
  information: string;
  createdAt?: Date;
  updatedAt?: Date;
  deleted?: boolean;
  isPending?: boolean;
  userName?: string;
  numberOfDays?: number;
}

export interface Holiday {
  id: number;
  employeeUserId: string;
  managementUserId: string;
  numberOfHolidays: number;
  transaction: string;
}

export interface User {
  c: string;
  co: string;
  delegateUserId?: string;
  department: string;
  description: string;
  leadId: string;
  leadName: string;
  name: string;
  title: string;
  userId: string;
}

export interface Delegate {
  userId: string;
  name: string;
}

export interface SignInButtonProps {
  className?: string;
}

export interface SignOutButtonProps {
  className?: string;
}

export interface RequestData {
  id: number;
  userId: string;
  userName: string;
  reason: AbsenceReason;
  startDate: Date;
  endDate: Date;
  status: AbsenceStatus;
  information: string;
  title: string;
}

export interface SelectedUserAbsence {
  status: AbsenceStatus;
  isPending: boolean;
  title: string;
  startDate: Date;
  endDate: Date;
  color: string;
  reason: AbsenceReason;
  borderColor: string;
  allDay: boolean;
}

export interface RequestFormProps {
  updatePendingRequests: (newRequest: RequestData) => void;
}

//HolidayForm
export interface HolidayUser {
  userId: string;
  name: string;
}

//HolidayForm
export interface HolidayFormProps {
  closePopUp: () => void;
}

//Calendar
export interface Resource {
  id: string;
  sections: string;
  title: string;
}

export const bankHolidayTypes = [
  'NATIONAL_HOLIDAY',
  'NATIONAL_HOLIDAY_ORTHODOX',
  'DE_FACTO_AND_BANK_HOLIDAY',
  'LOCAL_HOLIDAY'
];

export type CalendarFilter = 'all' | 'directReports' | 'includeIndirectReports';