import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

import {
  PublicClientApplication,
  EventType,
  AuthenticationResult,
  BrowserCacheLocation,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

const pca = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID as string,
    authority:
      'https://login.microsoftonline.com/5cd1919e-7174-44f9-866c-35e1e611a016',
    redirectUri: '/',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage
  }
});

pca.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    pca.setActiveAccount((event.payload as AuthenticationResult).account);
    console.log('Login success');
  }
});

const container = document.getElementById('root');
const root = createRoot(container as Element);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MsalProvider instance={pca}>
        <App />
      </MsalProvider>
    </BrowserRouter>
  </React.StrictMode>
);
