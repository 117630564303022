import React, { useState } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { Link as RouterLink } from 'react-router-dom';
import AbsenceFormPopup from '../AbsenceForm/AbsenceFormPopUp';
import Logo from '../../assets/Icons/NavBarIcons/Logo.svg';
import LogoutIcon from '../../assets/Icons/NavBarIcons/Logout.svg';
import './NavBar.css';

interface NavBarProps {
  groups?: string[];
}

const NavBar = (props: NavBarProps) => {
  const { groups } = props;
  const [activeLink, setActiveLink] = useState<string | null>(null);
  const [absenceFormOpen, setAbsenceFormOpen] = useState(false);
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleAbsenceFormOpen = () => {
    setAbsenceFormOpen(true);
  };

  const handleAbsenceFormClose = () => {
    setAbsenceFormOpen(false);
  };

  const handleLinkClick = (to: string) => {
    setActiveLink(to);
  };

  const handleSignIn = async () => {
    await instance.loginRedirect({
      scopes: ['user.read'],
    });
  };

  const handleSignOut = async () => {
    await instance.logoutRedirect();
  };
  return (
    <nav className='navbar'>
      <div className='logo-container'>
        <img src={Logo} alt='Logo' className='logo' />
        <span className='logo-text'>Absences</span>
      </div>
      <div className='nav-items'>
        {isAuthenticated ? (
          <>
            <RouterLink
              to='/'
              className={`nav-button ${activeLink === '/' ? 'active' : ''}`}
              style={{ width: '138px' }}
              onClick={() => handleLinkClick('/')}
            >
              Dashboard
            </RouterLink>
            <RouterLink
              to='/Calendar'
              className={`nav-button ${activeLink === '/Calendar' ? 'active' : ''
                }`}
              style={{ width: '203px' }}
              onClick={() => handleLinkClick('/Calendar')}
            >
              Absence Calendar
            </RouterLink>
            {groups !== undefined && groups.includes(process.env.REACT_APP_LEAD_GROUP as string) ?
              <RouterLink
                to='/LeadView'
                className={`nav-button ${activeLink === '/LeadView' ? 'active' : ''
                  }`}
                style={{ width: '134px' }}
                onClick={() => handleLinkClick('/LeadView')}
              >
                My Team
              </RouterLink> : null}
            {groups !== undefined &&
              (groups.includes(process.env.REACT_APP_MANAGEMENT_GROUP as string) ||
              groups.includes(process.env.REACT_APP_HR_GROUP as string)) ?
              <RouterLink
                to='/ManagementView'
                className={`nav-button ${activeLink === '/ManagementView' ? 'active' : ''
                  }`}
                style={{ width: '151px' }}
                onClick={() => handleLinkClick('/ManagementView')}
              >
                Management
              </RouterLink> : null}
            <button
              className='nav-button request-button'
              style={{ width: '243px' }}
              onClick={handleAbsenceFormOpen}
            >
              Request Absence
            </button>
            <div className='divider' />
            <button
              className='nav-button sign-out'
              style={{ width: '151px' }}
              onClick={async () => await handleSignOut()}
            >
              Sign Out
              <img src={LogoutIcon} alt='Logout Icon' className='logout-icon' />
            </button>
          </>
        ) : (
           <div className='sign-in-button-container'>
              <button className='nav-button sign-in' onClick={async () => await handleSignIn()}>
              Sign In
            </button>
          </div>
        )}
      </div>
      <AbsenceFormPopup
        open={absenceFormOpen}
        closePopUp={() => handleAbsenceFormClose()}
      />
    </nav>
  );
};

export default NavBar;
