import { Absence, User } from '../Types';
import workbookManager from '@microsoft/connected-workbooks';
import getUsername from './username';
import { getCountry, getNumberOfDays } from './absenceRequests';
import { IPublicClientApplication } from '@azure/msal-browser';

const exportAbsences = async(absences: Absence[], calendarDate: Date, users: User[], msalInstance: IPublicClientApplication): Promise<void> => {
  const lastDayOfMonth = new Date(calendarDate.getUTCFullYear(), calendarDate.getUTCMonth()+1, 0);
  lastDayOfMonth.setUTCFullYear(lastDayOfMonth.getFullYear(), lastDayOfMonth.getMonth(), lastDayOfMonth.getDate());
  lastDayOfMonth.setUTCHours(0, 0, 0, 0);

  const absencesForMonth = absences.filter((absence) =>
    absence.status === 'Approved' && (
    (absence.startDate >= calendarDate.toISOString() && absence.endDate <= lastDayOfMonth.toISOString()) ||
    (absence.startDate < calendarDate.toISOString() && absence.endDate >= calendarDate.toISOString()) ||
    (absence.startDate <= lastDayOfMonth.toISOString() && absence.endDate >= lastDayOfMonth.toISOString()))
  );
  const mappedAbsences = absencesForMonth.map(async(absence) => {
    let numberOfDays = 0;
    try {
      const country = getCountry(absence.userId, users);
      numberOfDays = await getNumberOfDays(absence.startDate, absence.endDate, msalInstance, country);
    } catch(error) {
      console.error('Cannot get country of deactivated User. Ignoring number of days calculation');
    }

    return [
      getUsername(absence.userId, users),
      absence.startDate,
      absence.endDate,
      absence.reason,
      absence.information,
      numberOfDays
    ];
  });

  const grid = {
    config: { promoteHeaders:true, adjustColumnNames:true },
    data: [
      ['Name', 'StartDate', 'EndDate', 'Reason', 'Information', 'WorkingDays'],
      ...await Promise.all(mappedAbsences)
    ]
  };
  const blob = await workbookManager.generateTableWorkbookFromGrid(grid);
  workbookManager.downloadWorkbook(blob, `Absences-${calendarDate.getUTCFullYear()}-${(calendarDate.getUTCMonth()+1).toString().padStart(2, '0')}.xlsx`);
};

const exportHolidayAccrual = async(holidayMap: {[key: string]: number}, sickLeaveMap: {[key: string]: number}, employees: User[]) => {
  const holidayAccrual = employees.map((employee) => {
    return [
      getUsername(employee.userId, employees),
      holidayMap[employee.userId],
      sickLeaveMap[employee.userId]
    ];
  });

  const grid = {
    config: {promoteHearder: true, adjustColumnNames: true},
    data: [
      ['Name', 'HolidayAccrual', 'SickLeaves'],
      ...holidayAccrual
    ]
  };
  const blob = await workbookManager.generateTableWorkbookFromGrid(grid);
  workbookManager.downloadWorkbook(blob, `HolidayAccrual-${new Date().getUTCFullYear()}-${(new Date().getUTCMonth()+1).toString().padStart(2, '0')}.xlsx`);
};

export {
  exportAbsences,
  exportHolidayAccrual
};