import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import ManagementView from './components/Management/MgmtView';
import LeadViewPage from './components/Leads/LeadView';
import Profile from './components/Dashboard/Profile';
import Calendar from './components/Calendar/CalendarView';
import { AuthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import './App.css';
import { getLogin } from './helpers/authHelper';
import NavBar from './components/NavBar/NavBar';

const App: React.FC = () => {
  const [groups, setGroups] = useState<string[] | undefined>();
  const isAuthenticated = useIsAuthenticated();
  const msal = useMsal();
  
  useEffect(() => {
    if (isAuthenticated === true) {
      const fetchLogin = async () => {
        const login = await getLogin(msal.instance);
        const { groups } = login.idTokenClaims as any;
        setGroups(groups);
      };
      fetchLogin();
    }
  }, [isAuthenticated, msal.instance]);

  return (
    <React.Fragment>
      <NavBar groups={groups}/>
      <AuthenticatedTemplate>
        <Routes>
          <Route path='/' element={<Profile groups={groups}/>} />
          <Route path='/Calendar' element={<Calendar groups={groups}/>} />
          <Route path='/RequestForm' />
          <Route path='/ManagementView' element={<ManagementView groups={groups}/>} />
          <Route path='/LeadView' element={<LeadViewPage groups={groups} />} />
        </Routes>
      </AuthenticatedTemplate>
    </React.Fragment>
  );
};

export default App;
