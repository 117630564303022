import React, { useCallback, useEffect, useState } from 'react';
import { Absence, Holiday, User } from '../../Types';
import { getHolidayBalance, getHolidayBalanceColor } from '../../helpers/holidays';
import { formatDate, getBackgroundColor, getCountry, getNumberOfDays, handleRequestHandled } from '../../helpers/absenceRequests';
import Comment from '../../assets/Icons/LeadIcons/Comment.svg';
import pattern from '../../assets/Icons/LeadIcons/pattern.png';
import checkMark from '../../assets/Icons/LeadIcons/checkMark.svg';
import cancel from '../../assets/Icons/LeadIcons/cancel.svg';
import { IPublicClientApplication } from '@azure/msal-browser';
import { getLogin } from '../../helpers/authHelper';
import axios from 'axios';
import getUsername from '../../helpers/username';
import openArrow from '../../assets/Icons/LeadIcons/openArrow.svg';

interface PendingRequestsProps {
  refreshView: () => void;
  holidays: Holiday[];
  msalInstance: IPublicClientApplication;
  users: User[];
  indirectReports: User[];
  sickDaysMap: {[key: string]: number};
  holidayBalanceMap: {[key: string]: number};
  sortByHolidayAccrual: boolean;
  setSortByHolidayAccrual: React.Dispatch<React.SetStateAction<boolean>>;
  sortBySickDays: boolean;
  setSortBySickDays: React.Dispatch<React.SetStateAction<boolean>>;
}

const sortByNameThenDate = (a: Absence, b: Absence) => {
  const result = (a.userName || '').localeCompare(b.userName || '');
  return result === 0 ? new Date(a.startDate).getTime() - new Date(b.startDate).getTime() : result;
};

const PendingRequests = (props: PendingRequestsProps) => {
  const {
    indirectReports,
    holidays,
    msalInstance,
    refreshView,
    users,
    sickDaysMap,
    holidayBalanceMap,
    sortByHolidayAccrual,
    setSortByHolidayAccrual,
    sortBySickDays,
    setSortBySickDays
  } = props;
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [pendingRequests, setPendingRequests] = useState<Absence[]>([]);
  const [isPendingRequestsOpen, setIsPendingRequestsOpen] = useState(true);

  const validateHasEnoughHolidays = useCallback(async(request: Absence): Promise<boolean> => {
    const holidayBalance = getHolidayBalance(request.userId, holidays);
    const absenceDays = await getNumberOfDays(request.startDate, request.endDate, msalInstance, getCountry(request.userId, [...users, ...indirectReports]));
    const remainingDays = holidayBalance - absenceDays;
    return remainingDays >= 0;
  },[holidays, msalInstance, users, indirectReports]);

  const numberOfOpenRequests = pendingRequests.filter(
    (request) => request.status === 'Requested'
  ).length;

  const fetchPendingRequests = useCallback(async() => {
    if (users.length > 0) {
      try {
        const login = await getLogin(msalInstance);

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASEURL}/pending-requests`,
          {
            headers: {
              Authorization: `Bearer ${login.idToken}`,
            },
          }
        );

        const updatedPendingRequestsPromises = response.data
          .filter((response: Absence) => {
            return users.map((user) => user.userId).includes(response.userId) || (
              response.delegatedToUserId !== undefined &&
              indirectReports.map((user) => user.userId).includes(response.delegatedToUserId) &&
              response.delegatedToUserId === msalInstance.getActiveAccount()?.idTokenClaims?.onprem_sid);
          })
          .map(async(response: Absence) => {
            let numberOfDays = 0;
            try {
              numberOfDays = await getNumberOfDays(response.startDate, response.endDate, msalInstance, getCountry(response.userId, [...users, ...indirectReports]));
            } catch(e) {
              console.error('Invalid numberOfDays calculation');
            }

            const userId = response.userId;

            const userName = getUsername(userId, [...users, ...indirectReports]);

            return {
              id: response.id,
              userId: response.userId,
              userName: userName,
              reason: response.reason,
              startDate: new Date(response.startDate),
              endDate: new Date(response.endDate),
              status: response.status,
              information: response.information,
              numberOfDays: numberOfDays,
            };
          });

        const updatedPendingRequests = await Promise.all(updatedPendingRequestsPromises);

        const sortedPendingRequests = updatedPendingRequests.sort(sortByNameThenDate);

        setPendingRequests(sortedPendingRequests);
      } catch (error) {
        console.error('Error fetching pending requests:', error);
      }
    }
  },[users, indirectReports, msalInstance]);

  const togglePendingRequests = useCallback(() => {
    setIsPendingRequestsOpen(s => !s);
  },[]);

  const toggleSortByHolidayAccrual = useCallback(() => {
    setSortBySickDays(false);
    setSortByHolidayAccrual(s => !s);
  },[setSortByHolidayAccrual, setSortBySickDays]);

  const toggleSortBySickDays = useCallback(() => {
    setSortByHolidayAccrual(false);
    setSortBySickDays(s => !s);
  },[setSortBySickDays, setSortByHolidayAccrual]);

  useEffect(() => {
    fetchPendingRequests();
  },[users, fetchPendingRequests]);

  useEffect(() => {
    if (sortByHolidayAccrual) {
      setPendingRequests(p => [...p.sort((a, b) => {
        return holidayBalanceMap[b.userId] - holidayBalanceMap[a.userId];
      })]);
    }
    if (sortBySickDays) {
      setPendingRequests(p => [...p.sort((a, b) => {
        return sickDaysMap[b.userId] - sickDaysMap[a.userId];
      })]);
    }
    if (!sortByHolidayAccrual && !sortBySickDays) {
      setPendingRequests(p => [...p.sort(sortByNameThenDate)]);
    }
  },[sortByHolidayAccrual, sortBySickDays, holidayBalanceMap, sickDaysMap]);

  return <React.Fragment>
    <div className='columns-title-container'>
      <div className='column-title'>
        <div className='icon-title' onClick={togglePendingRequests}>
          <img
            src={openArrow}
            alt='Open Arrow'
            style={{ width: '22px', height: '22px', transform: isPendingRequestsOpen ? 'rotate(0deg)' : 'rotate(-90deg)' }}
          />
          <h2>Waiting For Approval ({numberOfOpenRequests})</h2>
        </div>
      </div>
      <div className='column-title' style={{cursor: 'pointer'}} onClick={() => toggleSortByHolidayAccrual()}>
        <div className='icon-title'>
          {sortByHolidayAccrual ? <img
            src={openArrow}
            alt='Open Arrow'
            style={{ width: '22px', height: '22px', transform: 'rotate(0deg)' }}
          /> : null }
          <h2>Holiday Accrual</h2>
        </div>
      </div>
      <div className='column-title' style={{cursor: 'pointer'}} onClick={() => toggleSortBySickDays()}>
        <div className='icon-title'>
          {sortBySickDays ? <img
            src={openArrow}
            alt='Open Arrow'
            style={{ width: '22px', height: '22px', transform: 'rotate(0deg)' }}
          /> : null }
          <h2>Sick Days this year</h2>
        </div>
      </div>
      <div className='column-title'>
        <h2>Absences</h2>
      </div>
      <div className='column-title'>
        <h2>Status</h2>
      </div>
    </div>
    {isPendingRequestsOpen ? pendingRequests.map((request, index) => {
        const { numberOfDays } = request;
      
        const formattedStartDate = formatDate(new Date(request.startDate));
        const formattedEndDate = formatDate(new Date(request.endDate));
        return <div className='columns-container' key={index}>
          <div className='column' style={{ width: '350px' }}>
            <div className='user-info'>
              <span className='user-name'>{request.userName}</span>
            </div>
          </div>
          <div className='column' style={{ width: '305px' }}>
            <span className='holiday-accrual' style={{color: getHolidayBalanceColor(holidayBalanceMap[request.userId])}}>{holidayBalanceMap[request.userId]}</span>
          </div>
          <div className='column'>
            <span className='holiday-accrual'>{sickDaysMap[request.userId]}</span>
          </div>
          <div
            className='column'
            style={{
              width: '703px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              className='absence-container'
              style={{
                background: getBackgroundColor(request.reason)
                  .backgroundColor,
              }}
            >
              {getBackgroundColor(request.reason).icon && (
                <img
                  src={getBackgroundColor(request.reason).icon}
                  alt={request.reason}
                  className='absence-icon'
                />
              )}
              <p className='absence-date'>
                {`${numberOfDays} day${numberOfDays === 1 ? '' : 's'} `}
                {formattedStartDate === formattedEndDate ? formattedStartDate :
                `${formattedStartDate} - ${formattedEndDate}`}
                <img
                  src={Comment}
                  alt={request.reason}
                  className='comment-icon'
                  title={request.information || ''}
                />
              </p>
              <div className='pattern-overlay' title={`${request.reason}${request.information === '' ? '' : ' - '}${request.information}`} style={{ backgroundImage: `url(${pattern})` }}></div>
            </div>
          </div>
          <div
            className='column'
            style={{
              width: '338px',
              color: getBackgroundColor(request.reason).backgroundColor,
            }}
          >
            <div className='status-buttons-container'>
              <div className='status-buttons'>
                <button
                  disabled={buttonDisabled}
                  className='approval-btn'
                  onClick={async() => {
                    setButtonDisabled(true);
                    if (request.reason === 'Vacation') {
                      const enoughHolidays = await validateHasEnoughHolidays(request);
                      if (enoughHolidays === false) {
                        const confirmed = window.confirm('This request exceeds the number of available vacation days. Do you still want to approve it?');
                        if (!confirmed) {
                          setButtonDisabled(false);
                          return;
                        }
                      }
                    }
                    await handleRequestHandled('approve', request, msalInstance, () => {
                      setPendingRequests(pendingRequests.filter((req) => req.id !== request.id));
                      fetchPendingRequests();
                      refreshView();
                    });
                    setButtonDisabled(false);
                  }}
                >
                  <img
                    src={checkMark}
                    alt='Approve'
                    className='button-icon'
                  />
                  Approve
                </button>
                <button
                  disabled={buttonDisabled}
                  className='decline-btn'
                  onClick={async() => {
                    setButtonDisabled(true);
                    await handleRequestHandled('decline', request, msalInstance, () => {
                      setPendingRequests(pendingRequests.filter((req) => req.id !== request.id));
                      fetchPendingRequests();
                      refreshView();
                    });
                    setButtonDisabled(false);
                  }}
                >
                  <img src={cancel} alt='Decline' className='button-icon' />
                  Decline
                </button>
              </div>
            </div>
          </div>
        </div>;
    }) : null}
  </React.Fragment>;
};

export default PendingRequests;