import axios from 'axios';
import { Absence, AbsenceReason, BankHoliday, FormValues, RequestData, User, bankHolidayTypes } from '../Types';
import { getLogin } from './authHelper';
import { IPublicClientApplication } from '@azure/msal-browser';
import Vacation from '../assets/Icons/CalendarIcons/Absences/Vacation.svg';
import SickLeave from '../assets/Icons/CalendarIcons/Absences/SickLeave.svg';
import DayOff from '../assets/Icons/CalendarIcons/Absences/DayOff.svg';
import WorkTravel from '../assets/Icons/CalendarIcons/Absences/WorkTravel.svg';
import UnpaidLeave from '../assets/Icons/CalendarIcons/Absences/UnpaidLeave.svg';
import Other from '../assets/Icons/CalendarIcons/Absences/Other.svg';

const getAbsenceColor = (reason?: AbsenceReason | ''): string => {
  switch(reason) {
    case 'Vacation':
      return '#81B884';
    case 'Sick Leave':
      return '#9C6D95';
    case 'Day Off':
      return '#2CA2AA';
    case 'Unpaid Leave':
      return '#7A78D7';
    case 'Work Travel':
      return '#8D444D';
    case undefined:
      return '#D4D4D4';
    default:
      //other reasons according to UX layout
      return '#D47E19';
  }
};

const getBackgroundColorWithStatus = (absence: Absence) => {
  const { reason, status, deleted } = absence;
  const icon = getBackgroundColor(reason);
  if (status === 'Declined' || deleted === true) {
    return {
      backgroundColor: '#929292',
      icon: icon.icon
    };
  }
  return icon;
};

const getBackgroundColor = (reason: AbsenceReason | '') => {
  switch (reason) {
    case 'Sick Leave':
      return {
        backgroundColor: getAbsenceColor(reason),
        icon: SickLeave,
      };
    case 'Vacation':
      return {
        backgroundColor: getAbsenceColor(reason),
        icon: Vacation,
      };
    case 'Day Off':
      return {
        backgroundColor: getAbsenceColor(reason),
        icon: DayOff,
      };
    case 'Unpaid Leave':
      return {
        backgroundColor: getAbsenceColor(reason),
        icon: UnpaidLeave,
      };
    case 'Work Travel':
      return {
        backgroundColor: getAbsenceColor(reason),
        icon: WorkTravel,
      };
    default:
      return {
        backgroundColor: getAbsenceColor(reason),
        icon: Other,
      };
  }
};

const formatDate = (date: Date) => {
  const day = date.getUTCDate().toString().padStart(2, '0');
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const year = date.getUTCFullYear();
  return `${day}.${month}.${year}`;
};

const formatCalendarDate = (date: string): string => {
  const dateObj = new Date(date);
  return new Date(`${dateObj.getUTCFullYear()}-${(dateObj.getUTCMonth()+1).toString().padStart(2, '0')}-${(dateObj.getUTCDate()).toString().padStart(2, '0')}`).toISOString();
};

const getFormattedPeriod = (startDateIn: Date | string, endDateIn: Date | string) => {
  const startDate = new Date(startDateIn);
  const endDate = new Date(endDateIn);
  if (startDate.toISOString() === endDate.toISOString()) {
    return `${formatDate(startDate)}`;
  }
  return `${formatDate(startDate)} - ${formatDate(endDate)}`;
};

const handleRequestHandled = async (
  action: 'approve' | 'decline' | 'remove',
  request: RequestData | { id: number },
  msalInstance: IPublicClientApplication,
  refreshView: () => void
) => {
  try {
    const login = await getLogin(msalInstance);
    const absenceId = request.id;

    await axios.put(
      `${process.env.REACT_APP_BACKEND_BASEURL}/absences/${absenceId}/${action}`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${login.idToken}`,
        },
      }
    );

    refreshView();
  } catch (error: any) {
    console.error(`Error ${action}ing request:`, error);
    if (error?.response && error?.response?.data) {
      if (error?.response?.status === 404) {
        if (error.response.data.error) {
          const notFoundError = error.response.data.error;
          if (notFoundError === 'Pending request not found') {
            alert('Absence request not found. It is no longer pending. Try refreshing.');
          } else if (notFoundError === 'Request not found') {
            alert('Absence has already been removed');
          }
        }
      } else if (error?.response?.status === 400) {
        const notAllowedError = error.response.data.error;
        if (notAllowedError === 'Not allowed to remove past absences') {
          alert('Not allowed to remove past absences');
        }
        if (notAllowedError === 'Cannot approve past absences') {
          alert('Cannot approve past absences');
        }
      }
    }
  }
};

type entry = {
  bankHolidays: BankHoliday[];
  timestamp: Date;
}

interface cache {
  entries: {[key: string]: entry}
}

const bankHolidayCache: cache = {
  entries: {}
};

const checkCache = (key: string): boolean => {
  const entry = bankHolidayCache.entries[key];
  if (entry === undefined) {
    return false;
  }
  const comparisonDate = new Date(entry.timestamp);
  comparisonDate.setMinutes(entry.timestamp.getMinutes() + 1440);
  const now = new Date();
  if (now.toISOString() > comparisonDate.toISOString()) {
    return false;
  }
  return true;
};

const fetchBankHolidays = async (countries: Set<string>, calendarYear: number, msalInstance: IPublicClientApplication): Promise<BankHoliday[] | undefined> => {
  if (countries.size > 0) {
    const login = await getLogin(msalInstance);
    const requests = Array.from(countries.values()).map(async(country) => {
      const cacheKey = `${country}${calendarYear}`;
      const isCached = checkCache(cacheKey);
      if (isCached) {
        return {
          data: bankHolidayCache.entries[cacheKey].bankHolidays,
        };
      }
      
      const response = await axios.get(`${process.env.REACT_APP_HOLIDAY_API_URL}?country=${country}&year=${calendarYear}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${login.idToken}`,
        },
      });

      const entry = {
        bankHolidays: response.data,
        timestamp: new Date()
      };

      bankHolidayCache.entries[cacheKey] = entry;
      return response;
    });

    try {
      const responses = await Promise.all(requests);

      const bankHolidaysData: BankHoliday[] = responses.flatMap((response: { data: BankHoliday[], cacheKey?: string, request?: XMLHttpRequest }) => {
        return response.data.filter((holiday: any) => bankHolidayTypes.includes(holiday.type))
          .map((holiday: any) => ({
            ...holiday,
            name: `${holiday.name} (${holiday.iso})`,
          }));
      });

      return bankHolidaysData;
    } catch (error) {
      console.error('Error fetching bank holidays:', error);
    }
  }
};

const getWeekendsCount = (startDate: Date, endDate: Date): number => {
  let weekendsCount = 0;
  const currentDate = new Date(startDate);
  const oneDay = 24 * 60 * 60 * 1000;

  while (currentDate <= endDate) {
    const dayOfWeek = currentDate.getUTCDay();
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      weekendsCount++;
    }
    const nextDay = new Date(currentDate.getTime() + oneDay);
    currentDate.setTime(nextDay.getTime());
  }

  return weekendsCount;
};

const getBankHolidaysNotOnWeekend = async(
  startDate: Date,
  endDate: Date,
  msalInstance: IPublicClientApplication,
  defaultCountry?: string
): Promise<BankHoliday[]> => {
  const country: string | undefined = defaultCountry === undefined ? msalInstance.getActiveAccount()?.idTokenClaims?.ctry as string : defaultCountry;
  if (country === undefined) {
    throw new Error('Country is undefined');
  }
  const countries = new Set<string>();
  countries.add(country);
  const bankHolidays: BankHoliday[] = [];
  if (startDate.getUTCFullYear() === endDate.getUTCFullYear()) {
    const response = await fetchBankHolidays(countries, startDate.getUTCFullYear(), msalInstance);
    if (response !== undefined) {
      bankHolidays.push(...response);
    }
  } else {
    const startYearResponse = await fetchBankHolidays(countries, startDate.getUTCFullYear(), msalInstance);
    if (startYearResponse !== undefined) {
      bankHolidays.push(...startYearResponse);
    }
    const endYearResponse = await fetchBankHolidays(countries, endDate.getUTCFullYear(), msalInstance);
    if (endYearResponse !== undefined) {
      bankHolidays.push(...endYearResponse);
    }
  }
  return bankHolidays.filter((bankHoliday: BankHoliday) => {
    const bankHolidayDateOrig = new Date(bankHoliday.date);

    const bankHolidayDate = new Date(bankHolidayDateOrig);
    bankHolidayDate.setUTCFullYear(
      bankHolidayDateOrig.getUTCFullYear(),
      bankHolidayDateOrig.getUTCMonth(),
      bankHolidayDateOrig.getUTCDate()
    );
    bankHolidayDate.setUTCHours(0, 0, 0, 0);

    const check = bankHolidayTypes.includes(bankHoliday.type) &&
      bankHoliday.day !== 'Saturday' &&
      bankHoliday.day !== 'Sunday' &&
      bankHolidayDate.toISOString() >= startDate.toISOString() &&
      bankHolidayDate.toISOString() <= endDate.toISOString();
    return check;
  }) || [];
};

const getNumberOfDays = async(startDate: Date | string, endDate: Date | string, msalInstance: IPublicClientApplication, country?: string): Promise<number> => {
  const startDateUTC = new Date(startDate);
  const endDateUTC = new Date(endDate);
  // Calculate numberOfDays excluding weekends
  const oneDay = 24 * 60 * 60 * 1000;
  let numberOfDays = Math.round((endDateUTC.getTime() - startDateUTC.getTime()) / oneDay) + 1;
  const weekendsCount = getWeekendsCount(startDateUTC, endDateUTC);
  const bankHolidaysNotOnWeekends = await getBankHolidaysNotOnWeekend(startDateUTC, endDateUTC, msalInstance, country);
  numberOfDays -= weekendsCount;
  numberOfDays -= bankHolidaysNotOnWeekends.length;
  // Check its a valid number
  if (isNaN(numberOfDays) || numberOfDays < 1) {
    throw(new Error('Invalid numberOfDays calculation'));
  }
  return numberOfDays;
};

const getCountry = (userId: string, users: User[]): string => {
  const user = users.find((user) => user.userId === userId);
  if (user === undefined) {
    throw new Error('User not found');
  }
  return user.c;
};

const handleCreateAbsence = async (formValues: FormValues, msalInstance: IPublicClientApplication) => {
  const getRequest = () => {
    if (formValues.userId === msalInstance.getActiveAccount()?.idTokenClaims?.onprem_sid) {
      return {
        url: `${process.env.REACT_APP_BACKEND_BASEURL}/absences`,
        data: {
          startDate: formValues.startDate,
          endDate: formValues.endDate,
          reason: formValues.reason,
          information: formValues.information,
        }
      };
    } else {
      return {
        url: `${process.env.REACT_APP_BACKEND_BASEURL}/absences/on-behalf-of`,
        data: {
          startDate: formValues.startDate,
          endDate: formValues.endDate,
          reason: formValues.reason,
          information: formValues.information,
          userId: formValues.userId,
        }
      };
    }
  };
  const request: {
    url: string;
    data: Object;
  } = getRequest();

  try {
    const login = await getLogin(msalInstance);

    await axios.post(
      request.url,
      request.data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${login.idToken}`,
        },
      }
    );

    alert('Absence request created successfully!');
    return true;
  } catch (error: any) {
    console.error('Error:', error);
    if (error?.response && error?.response?.data && error?.response?.status === 400) {
      if (Array.isArray(error.response.data)) {
        const validationError = error.response.data[0];
        if (validationError?.keyword === 'formatMinimum') {
          alert('Start Date must be before End Date');
        }
      } else if (error.response.data.error) {
        const errorMessage = error.response.data.error;
        alert(errorMessage);
      }
    } else if (error?.response && error?.response?.data && error?.response?.status === 500) {
      if (error.response.data.error) {
        const errorMessage = error.response.data.error;
        if (errorMessage === 'Lead not set') {
          alert('Your Lead has not been set. Contact IT!');
        }
      }
    }
  }
};

export {
  getAbsenceColor,
  getBackgroundColorWithStatus,
  getBackgroundColor,
  formatDate,
  formatCalendarDate,
  getFormattedPeriod,
  handleRequestHandled,
  fetchBankHolidays,
  getNumberOfDays,
  getCountry,
  handleCreateAbsence,
};