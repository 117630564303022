import { Holiday } from '../Types';

const getHolidayBalance = (employeeUserId: string, holidays: Holiday[]): number => {
  let employeeHolidaysCount = 0;
  try {
    for (const holiday of holidays) {
      if (holiday.employeeUserId === employeeUserId) {
        if (holiday.transaction === 'Added') {
            employeeHolidaysCount += holiday.numberOfHolidays;
        } else if (holiday.transaction === 'Reduced') {
            employeeHolidaysCount -= holiday.numberOfHolidays;
        }
      }
    }
  } catch(e: any) {
    console.log(e.message);
  }
  return employeeHolidaysCount;
};

/*According to UX layout, if balance is over 25 it will be shown red.*/
const getHolidayBalanceColor = (balance: number) => {
  return balance > 25 ? '#BF0629' : '#000';
};

export {
  getHolidayBalance,
  getHolidayBalanceColor
};