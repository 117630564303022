import { IPublicClientApplication } from '@azure/msal-browser';
import { Absence, User } from '../Types';
import { getNumberOfDays } from './absenceRequests';

export const getSickDaysForCurrentYear = async(absences: Absence[], msalInstance: IPublicClientApplication, country?: string): Promise<number> => {
  const januaryFirst = new Date(`${new Date().getUTCFullYear()}-01-01`).toISOString();
  const decemberLast = new Date(`${new Date().getUTCFullYear()}-12-31`).toISOString();
  const sickLeaves = absences.filter((absence) =>
    (absence.reason === 'Sick Leave' &&
      absence.endDate >= januaryFirst &&
      absence.startDate <= decemberLast &&
      (absence.deleted === undefined || absence.deleted === false))
  );
  const sickLeaveArray = await Promise.all(sickLeaves.map(async(sickLeave): Promise<number> => {
    let startDate, endDate;
    if (sickLeave.startDate <= januaryFirst) {
      startDate = januaryFirst;
    }
    if (sickLeave.endDate > decemberLast) {
      endDate = decemberLast;
    }
    try {
      const numberOfSickDays = await getNumberOfDays(startDate || sickLeave.startDate, endDate || sickLeave.endDate, msalInstance, country);
      return numberOfSickDays;
    } catch(e) {
      return 0;
    }
  }));
  return sickLeaveArray.reduce((acc, cur) => {
    return acc + cur;
  }, 0);
};

export const getSickDaysForCurrentYearMappedToEmployeeId = async(employees: User[], absences: Absence[], msalInstance: IPublicClientApplication): Promise<{[key: string]: number}> => {
  const employeeSickDayPromises = employees.map(async(employee) => {
    const employeeAbsences = absences.filter((absence) => absence.userId === employee.userId);
    const numberOfSickDays = await getSickDaysForCurrentYear(employeeAbsences, msalInstance, employee.c);
    const employeeMap = {userId: employee.userId, numberOfSickDays};
    return employeeMap;
  });
  const employeeSickDays = await Promise.all(employeeSickDayPromises);
  const employeeSickDaysMap: {[key: string]: number} = {};
  employeeSickDays.forEach((employeeSickDay) => {
    employeeSickDaysMap[employeeSickDay.userId] = employeeSickDay.numberOfSickDays;
  });
  return employeeSickDaysMap;
};