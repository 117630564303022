import React from 'react';
import './HolidayandMgmt.css';
import HolidayForm from './HolidayForm';
import Union from '../../assets/Icons/CalendarIcons/Absences/Union.svg';

interface HolidayFormPopupProps {
    open: boolean;
    closePopUp: () => void;
}

const HolidayFormPopup: React.FC<HolidayFormPopupProps> = ({ open, closePopUp }) => {
    if (!open) {
        return null;
    }

    return (
        <div className='holiday-popup-overlay' onClick={() => closePopUp()}>
            <div className='holiday-popup-content' onClick={(e) => e.stopPropagation()}>
                <button onClick={closePopUp} className='absence-close-button'>
                    <img src={Union} alt='Close' className='close-icon' />
                </button>
                <div><h1 className='request-holiday-header'>Add Vacation Days</h1></div>
                <div className='holiday-form-container'>
                    <HolidayForm closePopUp={closePopUp} />
                </div>
            </div>
        </div>
    );
};

export default HolidayFormPopup;