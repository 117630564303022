import { Absence } from '../Types';

const getOverlappingDates = (matches: Absence[], startDate: Date, endDate: Date): Date[] => {
    const absenceDates: Date[] = [];
  
    matches.forEach((absence) => {
      const currentDate = new Date(absence.startDate);
      const stopDate = new Date(absence.endDate);
      while (currentDate <= stopDate) {
        if (startDate.toISOString() <= currentDate.toISOString() && currentDate.toISOString() <= endDate.toISOString()) {
          absenceDates.push(new Date(currentDate));
        }
        currentDate.setUTCDate(currentDate.getUTCDate() + 1);
      }
    });
  
    return absenceDates.sort((a, b) => a.getTime() - b.getTime());
  };

export { getOverlappingDates };