import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { HolidayUser, HolidayFormProps } from '../../Types';
import { getLogin } from '../../helpers/authHelper';

const HolidayForm: React.FC<HolidayFormProps> = ({ closePopUp }) => {
    const [userName, setUserName] = useState<string>('');
    const [numberOfHolidaysText, setNumberOfHolidaysText] = useState<string>('0');
    const [userList, setUserList] = useState<HolidayUser[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const msal = useMsal();

    useEffect(() => {
        const fetchAllUsers = async () => {
            try {
                const login = await getLogin(msal.instance);
    
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_BASEURL}/users`,
                    {
                        headers: {
                            Authorization: `Bearer ${login.idToken}`,
                        },
                    }
                );
    
                if (response.data) {
                    const sortedUsers: HolidayUser[] = response.data.sort(
                        (a: HolidayUser, b: HolidayUser) => a.name.localeCompare(b.name)
                    );
                    setUserList(sortedUsers);
                }
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchAllUsers();
    }, [msal.instance]);

    const handleSearchUsers = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.currentTarget;
        setUserName(value);
    };

    const handleSelectAll = () => {
        const allUserIds = userList.map((user) => user.userId);
        if (selectedUsers.length === userList.length) {
            setSelectedUsers([]);
        } else {
            setSelectedUsers(allUserIds);
        }
    };


    const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const numberOfHolidays = parseInt(numberOfHolidaysText.trim());

        if(isNaN(numberOfHolidays)) {
            alert('Invalid number');
            return;
        }

        if (selectedUsers.length === 0) {
            alert('No users selected');
            return;
        }

        try {
            const login = await getLogin(msal.instance);

            const setTransaction: 'Added' | 'Reduced' =
                numberOfHolidays >= 0 ? 'Added' : 'Reduced';

            await axios.post(`${process.env.REACT_APP_BACKEND_BASEURL}/holidays`,
                {
                    employeeUserIds: selectedUsers,
                    numberOfHolidays: Math.abs(numberOfHolidays),
                    transaction: setTransaction,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${login.idToken}`,
                    },
                }
            );

            console.log('Holidays logged successfully');

            // Alert so there is feedback for people testing it
            window.alert('Holidays logged successfully!');
            closePopUp();
        } catch (error) {
            console.error('Error logging holidays:', error);
        }

        setSelectedUsers([]);
    };

    const toggleUserSelection = (userId: string) => {
        if (selectedUsers.includes(userId)) {
            setSelectedUsers(selectedUsers.filter((id) => id !== userId));
        } else {
            setSelectedUsers([...selectedUsers, userId]);
        }
    };

    const handleCancelClick = () => {
        closePopUp();
    };

    return (
        <div className='holiday-form'>
            <div className='left-side'>
                <div className='searchbar'>
                    <input
                        type='text'
                        id='userName'
                        value={userName}
                        onChange={handleSearchUsers}
                        placeholder='Search employee...'
                        className='user-input'
                    />
                    <button onClick={handleSelectAll} className='holiday-select-button holiday-cancel-button'>{selectedUsers.length === userList.length ? 'Deselect All' : 'Select All'}</button>
                </div>
                <div className='selected-count'>
                    Selected ({selectedUsers.length})
                </div>
                <div className='user-list'>
                    {userList.filter((user) => user.name.toLowerCase().includes(userName.toLowerCase()) ).map((user) => (
                        <div key={user.userId} className='user-item' onClick={() => toggleUserSelection(user.userId)}>
                            <input
                                type='checkbox'
                                checked={selectedUsers.includes(user.userId)}
                                readOnly
                            />
                            <div className='user-details'>
                                <div className='user-name'>{user.name}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='right-side'>
                <form className='right-side-content'
                    onSubmit={async(e) => {
                        setSubmitDisabled(true);
                        await handleFormSubmit(e);
                        setSubmitDisabled(false);
                    }
                }>
                    <label htmlFor='numberOfHolidays' className='vacation-number-title'>Number of Vacation days:</label>
                    <input
                        type='number'
                        id='numberOfHolidays'
                        value={numberOfHolidaysText}
                        onChange={(e) => {
                            const { value } = e.currentTarget;
                            setNumberOfHolidaysText(value);
                        }}
                        className='holiday-number-input'
                    />
                    <div className='holiday-btn-container'>
                        <button
                            type='submit'
                            className='holiday-submit-button'
                            disabled={submitDisabled}
                        >
                            Submit
                        </button>
                        <button className='holiday-cancel-button' onClick={handleCancelClick}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div >
    );
};

export default HolidayForm;